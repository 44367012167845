import { useState } from 'react';
import tracking from '@/lib/tracking';

type CopiedValue = string | null;
type CopyFn = (event: React.MouseEvent) => Promise<boolean>; // Return success

type TTrackingEvent = {
  name: string;
  payload: Object;
};

function useClipboard(
  content: string,
  options: { afterClick?: () => void; event?: TTrackingEvent },
): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (event) => {
    event.preventDefault();
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(content);
      setCopiedText(content);
      options?.event && tracking.capture(options.event.name, options.event.payload);
      options?.afterClick?.();
      setTimeout(() => {
        setCopiedText(null);
      }, 1500);
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

export default useClipboard;
