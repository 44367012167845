'use client';
import Link from 'next/link';
import { IUser } from '@/types';
import * as Styled from './styled';
import useNamespaceCredits from '@/hooks/useNamespaceCredits';

import { Row } from '@/ui/primatives';
import { RocketIcon } from '@radix-ui/react-icons';

import ReferralButton from '@/components/ReferralButton';

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const ReferralBanner: React.FC<{ currentUser: IUser }> = ({ currentUser }) => {
  const { namespaceCredits } = useNamespaceCredits();
  const availableCredits = namespaceCredits?.available_credits;
  const isEmpty = namespaceCredits?.isEmpty;

  return (
    !isEmpty && (
      <Styled.ReferralBanner>
        <Styled.AnimatedGradientWrapper $enabled $glow className={'on'}>
          <Styled.Content>
            <Row gap={5} alignItems="center">
              <RocketIcon height={20} width={20} />
              You have <b>{USDollar.format(availableCredits)} available compute credits</b>
            </Row>
            <div>
              <Link href="https://docs.oxen.ai/features/models" className="link-underline" target="_blank">
                Learn more about Oxen Evaluations
              </Link>
            </div>
            <ReferralButton currentUser={currentUser} />
          </Styled.Content>
        </Styled.AnimatedGradientWrapper>
      </Styled.ReferralBanner>
    )
  );
};

export default ReferralBanner;
