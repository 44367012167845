'use client';
import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import * as Icon from '@/ui/Icon';
import * as Styled from './styled';
import useClipboard from '@/hooks/useClipboard';

type TTrackingEvent = {
  name: string;
  payload: Object;
};

type CopyTextControlProps = {
  id: string;
  assetType?: string;
  value: string;
  event?: TTrackingEvent;
  children?: React.ReactNode;
};

const CopyTextControl: React.FC<CopyTextControlProps> = ({ id, assetType = 'path', value, event, children }) => {
  const elementId = `copy-${id}`;
  const [copied, copy] = useClipboard(value, { event });

  const [isOpen, setIsOpen] = useState(false);

  const tooltopContent = copied ? 'Copied!' : `Copy ${assetType}`;

  return (
    <Styled.CopyTextControl
      data-tooltip-id={`${elementId}`}
      $copied={!!copied}
      onClick={copy}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {children || <Icon.Copy />}
      <Tooltip
        id={`${elementId}`}
        isOpen={!!copied || isOpen}
        content={tooltopContent}
        style={{
          zIndex: 9999,
          borderRadius: 5,
          padding: '4px 8px',
        }}
      />
    </Styled.CopyTextControl>
  );
};

export default CopyTextControl;
