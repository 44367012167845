import React from 'react';
import ReactMarkdown from 'react-markdown';
// Support Github Flavor Markdown
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { visit } from 'unist-util-visit';
import { Node } from 'unist';

import * as Styled from './styled';

import { IRepositoryFile } from '@/types';

type MarkdownTransformer = (tree: Node) => void;
interface ImageNode extends Node {
  type: 'image';
  url: string;
  title?: string;
  alt?: string;
}

const extractBaseFileUrl = (url: string): string | null => {
  const pattern = /^(.*\/api\/repos\/[^\/]+\/[^\/]+\/file\/[^\/]+)/;
  const match = url.match(pattern);
  return match ? match[0] : null;
};

export const transformLocalImages = (fileUrl: string) => {
  return (tree: Node) => {
    visit(tree, 'image', (node: ImageNode) => {
      if (node.type === 'image' && node.url && typeof node.url === 'string' && node.url.startsWith('/')) {
        node.url = extractBaseFileUrl(fileUrl) + node.url;
      }
    });
  };
};

const MarkdownViewer: React.FC<{ children: string }> = ({ children }) => {
  return (
    <Styled.MarkdownViewer>
      <ReactMarkdown className="markdown-body" rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
        {children}
      </ReactMarkdown>
    </Styled.MarkdownViewer>
  );
};

export default MarkdownViewer;
