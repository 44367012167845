import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as Icon from '@/ui/Icon';

import Form, { FieldSet, FormActions, ErrorMessage } from '@/components/Form';
import Input from '@/components/Input';
import { Stack } from '@/ui/primatives';
import Button from '@/ui/Button';
import { Identity } from '@/hooks/useIdentity';

import * as Styled from './styled';

import { IResponseError } from '@/lib/apiBase';
import { IProviderUsernameResponse } from '@/lib/usersApi';

export type FormValues = {
  name: string;
  username: string;
  password?: string;
};

interface RegisterDetailsFormProps {
  defaultValues?: FormValues;
  error?: IResponseError;
  onSubmit: Function;
  providerUsernameResponse?: IProviderUsernameResponse;
}

const formatErrorMessage = (error: IResponseError | undefined): string | undefined => {
  // Directly return undefined if error is not provided or neither title nor detail are present.
  if (!error || (!error.title && !error.detail)) {
    return undefined;
  }

  // If both title and detail are present, join them with a colon. Otherwise, return whichever is present.
  return [error.title, error.detail].filter(Boolean).join(': ');
};

const CompleteAccountForm: React.FC<RegisterDetailsFormProps> = ({
  defaultValues = {},
  error,
  onSubmit,
  providerUsernameResponse,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [usernameHint, setUsernameHint] = useState<string>();
  const { user } = Identity.useContainer();
  const submitLogin = async ({ name, username }: FormValues) => {
    onSubmit({ name, username });
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    username: Yup.string()
      .required('Required')
      .max(50)
      .matches(/^[a-zA-Z0-9-_]+$/, 'only alphanumeric, dashes and underscores')
      .matches(/^[^_-].*/, 'cannot start with an underscore or dash'),
    // password: Yup.string().min(8, 'Must be at least 8 characters'),
  });

  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    clearErrors();

    if (
      providerUsernameResponse?.error &&
      providerUsernameResponse?.status_message === 'no_provider_username_available'
    ) {
      const providerNames = providerUsernameResponse.error?.provider_names;
      let errorMessage = '';

      if (providerNames) {
        if (providerNames.length === 1) {
          // Case: Only one provider name
          errorMessage = `Your ${providerNames[0]} username is not available :(`;
        } else if (providerNames.length > 1) {
          // Case: Multiple provider names
          // Join all provider names except the last with commas, and the last one with "and"
          const providerNamesString = providerNames.slice(0, -1).join(', ') + ' and ' + providerNames.slice(-1);
          errorMessage = `Your ${providerNamesString} usernames are not available.`;
        }
      }
      setUsernameHint(errorMessage);
    }

    if (providerUsernameResponse?.username) {
      setValue('username', providerUsernameResponse.username);
      setUsernameHint(
        `We've pre-filled your ${providerUsernameResponse.provider_name} username, feel free to change it.`,
      );
    }

    if (user?.name) {
      setValue('name', user.name);
    }

    error?.params?.forEach((paramError) => {
      setError(paramError.key as any, { message: paramError.messages[0] });
      setFocus(paramError.key as any);
    });
    setErrorMessage(formatErrorMessage(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, clearErrors, setError, setFocus]);

  return (
    <Styled.Registration>
      <Form onSubmit={handleSubmit((v) => submitLogin(v))}>
        <h4>Get started with Oxen.ai</h4>
        <FieldSet>
          <Input name="name" label="Full Name" error={errors?.name} submit={register}></Input>
          <Input
            name="username"
            label="Username"
            error={errors?.username}
            hint={usernameHint}
            submit={register}
          ></Input>
          {/* <Input
            name="password"
            type="password"
            label="Password"
            error={errors?.password}
            submit={register}
            hint="Minimum 8 characters"
          ></Input> */}
        </FieldSet>
        <Stack gap={20}>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <FormActions>
            <Button stretch testId="register-submit" variant="primary">
              <span>CREATE ACCOUNT</span>
              <Icon.ArrowRight />
            </Button>
          </FormActions>
        </Stack>
      </Form>
    </Styled.Registration>
  );
};

export default CompleteAccountForm;
