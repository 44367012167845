'use client';

import MarkdownViewer from '@/components/MarkdownViewer';

const OxenInstallationInstructions: React.FC<{}> = ({}) => (
  <MarkdownViewer>
    {`
## Installation

\`\`\`
$ brew tap Oxen-AI/oxen
$ brew install oxen
\`\`\`

Find more platform installation instructions in the [developer docs](https://docs.oxen.ai/getting-started/install).

## Configure User

\`\`\`
$ oxen config --name <NAME> --email <EMAIL>
$ oxen config --auth hub.oxen.ai <API_KEY>
\`\`\`

## Create First Repo

\`\`\`
$ cd path/to/repo
$ oxen init
$ echo "# My First Repo" > README.md
$ oxen add README.md
$ oxen commit -m "initial commit"
$ oxen create-remote --name USERNAME/FIRST_REPO_NAME
$ oxen config --set-remote origin <URL> # make sure it begins with hub.oxen.ai
$ oxen push origin main
\`\`\`

          `}
  </MarkdownViewer>
);

export default OxenInstallationInstructions;
