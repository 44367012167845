'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const SectionContainer = styled.div<{ $alternate?: boolean; $last?: boolean; $hideMobile?: boolean }>`
  display: flex;
  z-index: 1;
  flex-direction: ${(props) => (props.$alternate ? 'row-reverse' : 'row')};
  gap: 100px;
  min-height: 400px;

  padding: 40px 0 60px 0;

  border-bottom: ${(props) => (props.$last ? 'none' : `1px solid ${colors.secondary.lightGray3}`)};

  & > * {
    flex: 1;
    width: 50%;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  img,
  video {
    width: 100%;
    object-fit: contain;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid ${colors.secondary.lightGray2};
  }

  & > div {
    flex: 1;
    display: flex;
    &:first-child {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    &:not(:first-child) {
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: ${breakPoints.mobilePortrait}) {
    display: ${(props) => (props.$hideMobile ? 'none' : 'flex')};
    flex-direction: column;
    gap: 20px;
    padding: 20px 10px 40px;
    & > * {
      width: 100%;
    }
  }
`;

export const SectionContent = styled.div`
  @media (max-width: ${breakPoints.mobilePortrait}) {
    .cta {
      display: none;
    }
  }
`;

export const SectionImage = styled.div`
  .cta {
    display: none;
  }
  @media (max-width: ${breakPoints.mobilePortrait}) {
    .cta {
      display: block;
      padding-top: 10px;
      button {
        min-width: 50vw;
        max-width: 90vw;
      }
    }
  }
`;
