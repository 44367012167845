'use client';

import styled from 'styled-components';
import { colors, toRgba, mixins } from '@/styles';

const gradientColors = ['#f87a7a', '#f9947d', '#f57ab1', '#e07de8', '#dbb2ed', '#efb4c9'];
const gradientPositions = [0, 20, 40, 60, 80, 100];

const alpha = 0.75;
const alphaGradientColors = gradientColors.map((color) => toRgba(color, alpha));

const gradientSet = alphaGradientColors.map((color, index) => `${color} ${gradientPositions[index]}%`);

export const FeatureGrid = styled.div`
  /* background: ${colors.secondary.lightGray1}; */

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        th {
          background: ${colors.primary.white};
          &:first-child {
            border-left: none;
            border-top: none;
            background: none;
          }
        }
      }
    }

    tbody {
      tr {
        background: ${colors.primary.white};
        &.oxen-ai {
          background: linear-gradient(90deg, ${gradientSet.join(',')});
        }
      }
    }

    th,
    td {
      position: relative;
      padding: 15px 10px;
      border: 1px solid ${colors.secondary.mediumGray};
      text-align: center;
      &.tool {
        text-align: left;
        font-weight: 600;
      }
      .caveat {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 12px;
        font-weight: 600;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.secondary.mediumGray};
      }
    }

    .marker {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: ${colors.primary.white};
      width: 20px;
      height: 20px;
      border-radius: 10px;
      padding: 5px;
      svg {
        min-width: 20px;
        height: 20px;
      }
    }

    .check {
      color: ${colors.semantic.positive.primary};
      svg {
        stroke: ${colors.semantic.positive.primary};
        stroke-width: 1px;
      }
    }

    .cross {
      color: ${colors.semantic.negative.primary};
      svg {
        stroke: ${colors.semantic.negative.primary};
        stroke-width: 1px;
      }
    }
  }
`;

export const Caveats = styled.div`
  margin-top: 20px;
  /* max-width: 500px; */
  table {
    font-size: 12px;
    width: 100%;
    border-collapse: collapse;
    td {
      padding: 5px;
      text-align: left;
      vertical-align: top;
      &.caveat-hint {
        white-space: nowrap;
        font-weight: 600;
      }
    }
  }
`;
