'use client';

import * as Icon from '@/ui/Icon';
import Link from 'next/link';
import Tag from '@/ui/Tag';
import { IRepository, IUser } from '@/types';
import useRepositoryStars from '@/hooks/useRepositoryStars';
import * as Styled from './styled';
import { IRepositoryStarResponse } from '@/lib/repositoryStarsApi';
import { Tooltip } from 'react-tooltip';
import tracking from '@/lib/tracking';
import { Identity } from '@/hooks/useIdentity';

export const RepositoryStars: React.FC<{
  repository: IRepository;
  currentUser?: IUser;
  starCount: number;
  starred: boolean;
  addStar: () => void;
  removeStar: () => void;
  simple?: boolean;
}> = ({ repository, starCount, starred, addStar, removeStar, simple, currentUser }) => {
  const toggleStar = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    if (!currentUser) return;
    starred ? removeStar() : addStar();
  };

  return (
    <>
      <Styled.RepositoryStarControl
        data-tooltip-id="my-tooltip"
        data-tooltip-content="You must be signed in to star a repo"
      >
        {simple ? (
          <Styled.SimpleStar>
            <span onClick={toggleStar}>{starred ? <Icon.Star fill="gold" stroke="goldenRod" /> : <Icon.Star />}</span>
            <Link href={`/${repository.namespace}/${repository.name}/stargazers`}>
              <span>{starCount}</span>
            </Link>
          </Styled.SimpleStar>
        ) : (
          <Link href={`/${repository.namespace}/${repository.name}/stargazers`}>
            <Tag small variant="default" counter={starCount}>
              <span onClick={toggleStar}>{starred ? <Icon.Star fill="gold" stroke="goldenRod" /> : <Icon.Star />}</span>
              <span>{starred ? 'Starred' : 'Star'}</span>
            </Tag>
          </Link>
        )}
      </Styled.RepositoryStarControl>
      {!currentUser && (
        <Tooltip
          id="my-tooltip"
          style={{ backgroundColor: 'var(--color-secondary-lightGray3)', color: '#000' }}
          place="bottom"
        />
      )}
    </>
  );
};

const RepositoryStarControl: React.FC<{
  repository: IRepository;
  initialResponse?: IRepositoryStarResponse;
  simple?: boolean;
}> = ({ repository, initialResponse, simple }) => {
  const { user: currentUser } = Identity.useContainer();
  const { starCount, starred, star, unStar, status, error } = useRepositoryStars({
    repository,
    currentUser,
    initialData: initialResponse,
  });

  const addStar = () => {
    star();
    tracking.capture('repository_star_added', {
      repository_id: repository.id,
      repository_name: repository.name,
      repository_namespace: repository.namespace,
    });
  };

  const removeStar = () => {
    unStar();
    tracking.capture('repository_star_removed', {
      repository_id: repository.id,
      repository_name: repository.name,
      repository_namespace: repository.namespace,
    });
  };

  return (
    <RepositoryStars
      currentUser={currentUser}
      repository={repository}
      starCount={starCount}
      starred={starred}
      addStar={addStar}
      removeStar={removeStar}
      simple={simple}
    />
  );
};

export default RepositoryStarControl;
